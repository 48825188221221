import { Flex, Text } from '@bonobolabs/mds-web-ui-gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { Link } from './Link'

export const SupportLink = () => (
  <Link
    to="/support"
    lineHeight={1.8}
    color="aqua"
    fontWeight="bold"
    mt={[-1, 4]}
    px={2}
    py={1}
    ml={-2}
    mb={-1}
    fontSize={0}
  >
    ← Support
  </Link>
)

const NavTitle = props => (
  <Text
    color="grayapptitle"
    fontSize={2}
    textAlign="left"
    fontWeight="500"
    py={[1, 2]}
    {...props}
  />
)

const NavTabWrapper = props => (
  <Flex
    mb={4}
    mr={3}
    css={{
      borderBottom: '1px solid #7E7E7E'
    }}
    pt={[1, 2]}
    alignItems="center"
    {...props}
  />
)

const Dot = () => (
  <NavTitle px={2} fontSize="1.5em" lineHeight={0.7}>
    &middot;
  </NavTitle>
)

export const NavTabsVH = ({ app }) => (
  <NavTabWrapper>
    <Link to={`/support/${app.toLowerCase()}/`} lineHeight="normal">
      <NavTitle color="aqua" fontWeight="400">
        {app} Help
      </NavTitle>
    </Link>
    <Dot />
    <NavTitle>Version History</NavTitle>
  </NavTabWrapper>
)
NavTabsVH.propTypes = {
  app: PropTypes.string.isRequired
}

export const NavTabsHelp = ({ app }) => (
  <NavTabWrapper>
    <NavTitle id="title">{app} Help</NavTitle>
    <Dot />
    <Link to={`/new/${app.toLowerCase()}/`} lineHeight="normal">
      <NavTitle color="aqua" fontWeight="400">
        Version History
      </NavTitle>
    </Link>
  </NavTabWrapper>
)
NavTabsHelp.propTypes = {
  app: PropTypes.string.isRequired
}
