import { Text as _Text } from '@bonobolabs/mds-web-ui-gatsby'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { gsap, ScrollTrigger, TextPlugin } from 'gsap/all'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'
import { Heading as _Heading } from 'rebass/styled-components'
import styled from 'styled-components'
import { color, flexbox, layout, space, typography } from 'styled-system'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(TextPlugin)

export const fontSizes = {
  massive: 'min(13vh, min(15.1vw, 124px))',
  huge: 'min(11vh, min(15vw, 116px))',
  extraLarge: 'min(9vh, min(14vw, 96px))',
  large: ['min(7vh, min(10.5vw, 72px))', 'min(8vh, min(12vw, 72px))'],
  medium: 'min(5vh, min(9vw, 56px))',
  mediumSmall: 'min(4.8vh, min(7.5vw, 42px))',
  small: 'min(4.5vh, min(6vw, 32px))',
  extraSmall: 'min(4vh, min(5vw, 28px))',
  extraExtraSmall: 'min(3.8vh, min(4.8vw, 26px))',
  tiny: 'min(3vh, min(4vw, 22px))',
  miniscule: 'min(2.8vh, min(3.8vw, 16px))'
}

export const lineHeights = {
  h1: 1,
  h2: 1.15,
  h3: 1.15,
  h4: 1.2,
  p: 1.3
}

const headingSizes = {
  h1: fontSizes.massive,
  h2: fontSizes.huge,
  h3: fontSizes.large,
  h4: fontSizes.medium,
  p: fontSizes.extraSmall
}

export const Heading = forwardRef(
  (
    {
      as = 'h1',
      animate = true,
      start = 'top bottom',
      children,
      label,
      color = 'var(--msk-color)',
      ...props
    },
    ref
  ) => {
    const spanRef = useRef()
    useImperativeHandle(ref, () => spanRef.current)

    useEffect(() => {
      const span = spanRef.current
      if (!span) {
        return
      }

      if (!animate) {
        gsap.set(span, { opacity: 1 })
        return
      }

      const ctx = gsap.context(() => {
        gsap.set(span, { opacity: 0 })
        gsap.to(span, {
          scrollTrigger: {
            trigger: span,
            start,
            toggleActions: 'restart none none reverse',
            markers: !!label,
            id: label || ''
          },
          duration: 0.5,
          opacity: 1,
          ease: 'power3'
        })
      })

      return () => {
        ctx.revert()
      }
    }, [animate, start, label])

    return (
      <BaseHeading
        as={as}
        color={color}
        fontSize={headingSizes[as]}
        lineHeight={lineHeights[as]}
        textAlign="left"
        fontWeight="800"
        {...props}
        ref={spanRef}
      >
        {children}
      </BaseHeading>
    )
  }
)

export const Paragraph = forwardRef(
  (
    {
      maxWidth = '50ch',
      delay = 0.05,
      animate = true,
      start = 'top bottom',
      children,
      ...props
    },
    ref
  ) => {
    const paragraphRef = useRef()
    useImperativeHandle(ref, () => paragraphRef.current)

    useEffect(() => {
      if (!animate) {
        gsap.set(paragraphRef.current, { opacity: 1 })
        return
      }

      const ctx = gsap.context(() => {
        requestAnimationFrame(() => {
          if (!paragraphRef.current) {
            return
          }

          gsap.set(paragraphRef.current, { opacity: 0 })
          gsap.to(paragraphRef.current, {
            scrollTrigger: {
              trigger: paragraphRef.current,
              start,
              toggleActions: 'restart none none reverse'
            },
            duration: 0.75,
            opacity: 1,
            ease: 'power3',
            delay
          })
        })
      })

      return () => {
        ctx.revert()
      }
    }, [animate, delay, start])

    return (
      <Text
        as="p"
        color="inherit"
        fontSize={headingSizes.p}
        lineHeight={lineHeights.p}
        textAlign="left"
        maxWidth={maxWidth || '26ch'}
        fontWeight="600"
        {...props}
        ref={paragraphRef}
      >
        {children}
      </Text>
    )
  }
)

const BaseHeading = styled(_Heading).withConfig({
  shouldForwardProp
})(
  {
    zIndex: 5,
    marginBlockStart: 0,
    textDecoration: 'none'
  },
  typography,
  space,
  layout,
  color,
  flexbox
)

const Text = styled(_Text).withConfig({
  shouldForwardProp
})(
  {
    zIndex: 5
  },
  typography,
  space,
  layout,
  flexbox,
  color
)
