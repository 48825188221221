import { Box, Flex, Text } from '@bonobolabs/mds-web-ui-gatsby'
import { MDXProvider } from '@mdx-js/react'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Heading } from '../../utils/Typography'

import LanguageSelector from '../../WhatsNew/LanguageSelector'
import { SkipNavContent } from '../SkipLink'
import { ActionsIcon, FlowIcon, TimepageIcon } from './Icon'
import Review from './Review'
import { Screenshot } from './Screenshot'
import TOC from './TOC'
import { Tab, Tabs } from './Tabs'
import Vimeo from './Vimeo'
import './style.css'

const SectionTitle = props => (
  <Text
    className="section"
    color="#9B9B9B"
    fontSize={10}
    css={{ textTransform: 'uppercase' }}
    mb={[2, 3]}
    {...props}
  />
)

const Title = props => (
  <Heading
    as="h1"
    fontWeight="semibold"
    fontSize={[4, 28]}
    color="grayapptitle"
    fontFamily="sans"
    {...props}
  />
)

const Link = ({ href, ...rest }) => {
  if (href.startsWith('/support/')) {
    return <GatsbyLink to={href} {...rest} />
  }

  return <a href={href} {...rest} />
}

const components = {
  Vimeo,
  TimepageIcon,
  ActionsIcon,
  FlowIcon,
  Tabs,
  Tab,
  Review,
  a: Link,
  Screenshot
}

const DocsContent = ({
  title,
  children,
  section,
  slug,
  baseURL,
  tableOfContents
}) => {
  // Can pass in body (from page query) or children (pre-rendered)
  const ref = useRef(null)
  // useAutoplayVideos(ref)
  return (
    <>
      <SkipNavContent />

      <Box
        as="article"
        ref={ref}
        css={{
          '& .iphone .gatsby-resp-image-wrapper': {
            marginTop: '1.5em',
            marginBottom: '1.5em'
          },
          '& .iphone.portrait .gatsby-resp-image-wrapper': {
            // 300*650px image, 31px target border radius
            borderRadius: `${(31 / 300) * 100}%/${(31 / 650) * 100}% !important`
          },
          '& .iphone.landscape .gatsby-resp-image-wrapper': {
            borderRadius: `${(31 / 650) * 100}%/${(31 / 300) * 100}% !important`
          },
          '& .no-border .gatsby-resp-image-wrapper': {
            borderRadius: 0
          },
          '& .side-by-side': {
            display: 'flex',
            '& > span': {
              flex: 1
            },
            'span + span': {
              marginLeft: '8px !important'
            },
            '& > div': {
              flex: 1
            },
            'div + div': {
              marginLeft: '8px !important'
            }
          },
          '& .center': {
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        }}
      >
        <Flex justifyContent="space-between">
          <SectionTitle>{section}</SectionTitle>
          {slug && <LanguageSelector baseURL={baseURL} slug={slug} />}
        </Flex>

        <Title>{title}</Title>
        <TOC tableOfContents={tableOfContents} />

        <MDXProvider components={components}>{children}</MDXProvider>
      </Box>
    </>
  )
}

DocsContent.propTypes = {
  title: PropTypes.string,
  section: PropTypes.string,
  children: PropTypes.node,
  tableOfContents: PropTypes.object
}

export { DocsContent as default }
